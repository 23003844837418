export const errorMessages = {
  REQUIRED: 'Required',
  NAME_IS_INVALID: 'Name is Invalid ',
  ATLEAST_3_CHARACTERS: 'Should atleast be 3 characters',
  ATLEAST_4_CHARACTERS: 'Should atleast be 4 characters',
  ATMOST_50_CHARACTERS: 'Should be less than 50 characters ',
  ATLEAST_8_CHARACTERS: 'Should atleast be 8 characters ',
  ATMOST_15_CHARACTERS: 'Should be less than 15 characters ',
  CONTACT_NUMBER_ALREADY_EXISTS: 'Contact Number Already Exists ',
  CONTACT_NUMBER_IS_INVALID: 'Contact Number is Invalid',
  EMAIL_ID_IS_INVALID: 'Email Id is Invalid',
  EMAIL_ALREADY_EXISTS: 'Email Id Already Exists',
  FIELD_IS_REQUIRED: 'Required',
  USERNAME_MIN_LENGTH: 'Username should be minimum 3 char',
  FLEET_ID_IS_INVALID: 'Fleet Id is Invalid',
  EVENT_IS_INVALID: 'Event is Invalid',
  // email id and confirm id
  EMAIL_ID_IS_REQUIRED: 'Email ID is required',
  EMAIL_IS_INVALID: 'Email is invalid',
  CONFIRM_EAMIL_ID_IS_REQUIRED: 'Confirm Email ID is required',
  EMAIL_ID_MUST_MATCH: 'Email Id must match',
  EMAIL_ALREADY_EXIST: 'This email id already exists',
  MUST_MATCH_PASSWORD: 'Password must match',
  LOGIN_PASSWORD_MIN_LENGTH: 'Password should be minimum 6 char',
  LOGIN_PASSWORD_MAX_LENGTH: 'Password should be minimum 20 char',
  WENT_WRONG: 'Something went wrong, try again after sometime',
  TERMS_AND_CONDITIONS_REQUIRED: 'Please select the checkbox to login',
  INVALID_DATE: 'Invalid Date',
  INCORRECT_DATE_TIME: 'Date Time Range must be less than or equal to 72 hours',
  INCORRECT_DATE_TIME_7DAYS: 'Date Time Range must be less than or equal to 7 days',
  INCORRECT_DATE_TIME_3DAYS: 'Date Time Range must be less than or equal to 3 days',
  INCORRECT_DATE_TIME_15DAYS: 'Date Time Range must be less than or equal to 15 days',
  INCORRECT_DATE_TIME_24: 'Date Time Range must be less than or equal to 24 hours',
  VIN_VALID: '17 digit VIN Number (A-Z 0-9)',
  VIN_IS_INVALID: 'Vin is invalid',
  REG_IS_INVALID: 'Registration is Invalid',
  MAKE_IS_INVALID: 'Make is Invalid',
  WHEELS_IS_INVALID: 'No of Wheels is Invalid',
  LADEN_IS_INVALID: 'Laden weight is Invalid',
  VA_IS_INVALID: 'Vehicle Application is Invalid',
  AN_IS_INVALID: 'Account Name is Invalid',
  CN_IS_INVALID: 'Corridor is Invalid',
  SPEC_IS_INVALID: 'Specification is Invalid',
  VN_IS_INVALID: 'Vendor Name is Invalid',
  VC_IS_INVALID: 'Vendor Company is Invalid',
  UNLADEN_IS_INVALID: 'Unladen is Invalid',
  TONNAGE_IS_INVALID: 'Tonnage is Invalid',
  ENGNO_IS_INVALID: 'Engine Number is Invalid',
  DATA_OUT_OF_RANGE: "Note: Only 31 Days of  data can be selected or visible for user logs",
  DISCLAIMER: "Disclaimer: Date Time Range must be less than or equal to 72 hours",
  DISCLAIMER_3DAYS : 'Disclaimer: Date Time Range must be less than or equal to 3 days',
  DISCLAIMER_3DAYS_NOTIME : 'Disclaimer: Date Range must be less than or equal to 3 days',
  DISCLAIMER_7DAYS : "Disclaimer: Date Time Range must be less than or equal to 7 days",
  DISCLAIMER_15DAYS : "Disclaimer: Date Time Range must be less than or equal to 15 days",
  DISCLAIMER_72: "Disclaimer: Date Time Range must be less than or equal to 72 hours",
  DISCLAIMER_24: "Disclaimer: Date Time Range must be less than or equal to 24 hours",
  FUEL_ALERTS_DISCLAIMER: "Disclaimer: Date Time Range must be less than or equal to 15 days",
  FILE_IS_REQUIRED: 'Select file',
  PAN_IS_INVALID:'PAN number format eg. ABCDE1234Y',
  MOBILE_NO_IS_INVALID:'Mobile number is invalid'
};
